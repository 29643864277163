import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import Navbar from './Components/navbar';
import Home from './Components/content';
import Movies from './Components/movies';
import Shows from './Components/Shows';
import Kids from './Components/kids';
import Livetwo from './Components/LIVETWO';
import Vlc from './Components/live';
import Series from './Components/Series';
import Ipl from './Components/ipl';
import Stream from './Components/stream';
import Login from './Components/Login';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

const MainApp = () => {
  const [user, setUser] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    } else if (location.pathname === '/Albums') {
      window.location.href = 'https://shadow-music.netlify.app/';
    } else if (location.pathname === '/weather') {
      window.location.href = 'https://shadow-weather.netlify.app/';
    } else if (location.pathname === '/news') {
      window.location.href = 'https://shadow-news.netlify.app/';
    } else if (location.pathname === '/Video') {
      window.location.href = 'https://shadow-videos-1205.netlify.app/';
    }
  }, [location, navigate]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "allowedUsers", currentUser.uid));
        if (userDoc.exists()) {
          setUser(currentUser);
          setIsAllowed(true);
        } else {
          await signOut(auth);
          setUser(null);
          setIsAllowed(false);
        }
      } else {
        setUser(null);
        setIsAllowed(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading && (!user || !isAllowed) && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [user, isAllowed, location, navigate, loading]);

  const handleNavClick = (component) => {
    if (component === 'Albums') {
      window.location.href = 'https://shadow-music.netlify.app/';
    } else if (component === 'weather') {
      window.location.href = 'https://shadow-weather.netlify.app/';
    } else if (component === 'news') {
      window.location.href = 'https://shadow-news.netlify.app/';
    } else if( component === 'Video') {
      window.location.href = 'https://shadow-videos-1205.netlify.app/';
    }
else {
      navigate(`/${component}`);
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
    setIsAllowed(false);
    navigate('/login');
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      {user && isAllowed ? (
        <>
          <Navbar onNavClick={handleNavClick} username={user.displayName || "User"} onLogout={handleLogout} />
          <Routes>
            <Route path="/home" element={<Home onNavClick={handleNavClick} />} />
            <Route path="/TV" element={<Livetwo />} />
            <Route path="/Movies" element={<Movies />} />
            <Route path="/Shows" element={<Shows />} />
            <Route path="/Kids" element={<Kids />} />
            <Route path="/Series" element={<Series />} />
            <Route path="/Sports" element={<Stream />} />
            <Route path="/Mobile" element={<Vlc />} />
            <Route path="/IPL" element={<Ipl />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
};

export default App;

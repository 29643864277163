import { useEffect, useState } from "react";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { auth, provider } from "../firebase";
import { db } from "../firebase"; // Firestore instance
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "allowedUsers", currentUser.uid));
        if (userDoc.exists()) {
          setUser(currentUser);
          setIsAllowed(true);
          navigate("/home");
        } else {
          alert("Access Denied: Your email is not authorized.");
          await signOut(auth);
          setUser(null);
          setIsAllowed(false);
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDoc = await getDoc(doc(db, "allowedUsers", user.uid));

      if (userDoc.exists()) {
        setUser(user);
        setIsAllowed(true);
        navigate("/home");
      } else {
        alert("Access Denied: Your email is not authorized. Contact Admin");
        await signOut(auth);
        setUser(null);
        setIsAllowed(false);
      }
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAllowed(false);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="https://github.com/vijesh0512/image/blob/main/6ba2ef95.jpg?raw=true" alt="Chat App Logo" className="logo" />
        <h2>Welcome to my OTT Website</h2>
        {user && isAllowed ? (
          <>
            <p>Signed in as: <strong style={{ color: "white", fontSize: "18px" }}>{user.displayName || "User"}</strong></p>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <button onClick={signInWithGoogle}>Sign in with Google</button>
        )}
      </div>
    </div>
  );
};

export default Login;

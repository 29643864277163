import React, { useState, useEffect } from 'react';
import './live.css';
import './album.css';

const Ipl = [
  {name:'Highlights',image:'https://cdni.autocarindia.com/Utils/ImageResizerV2.ashx?n=https://cdni.autocarindia.com/Stuff/Uploads/ArticleImages/638781481562352107_ipl_logo.jpg&w=883&h=662&q=70&c=1',shows:[
    {name:'Match 14 : RCB vs GT',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/2414/1743613572414-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040218/in/tam/v1/avc/1743621051535/hls/plain/media-4/index.m3u8'},
    {name:'Match 13 : LSG vs PBKS',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/1330/1743526351330-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040215/in/tam/v1/avc/1743536425510/hls/plain/media-1/index.m3u8'},
    {name:'Match 12 : MI vs KKR',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/432/1743439350432-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040212/in/tam/v1/avc/1743449260676/hls/plain/media-4/index.m3u8'},
    {name:'Match 11 : RR vs CSK',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/6843/1743357696843-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040209/in/tam/v1/avc/1743367150626/hls/plain/media-1/index.m3u8'},
    {name:'Match 10 : DC vs SRH',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/2107/1743340192107-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040206/in/tam/v2/avc/1743347928652/hls/plain/media-1/index.m3u8'},
    {name:'Match 9 : GT vs MI',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3853/1743270263853-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040203/in/tam/v3/avc/1743281378611/hls/plain/media-4/index.m3u8'},
    { name: 'Match 8 : CSK vs RCB',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/3783/1743182423783-i',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040200/in/tam/v3/avc/1743194973627/hls/plain/media-1/index.m3u8'},
    { name: 'Match 7 : SRH vs LSG',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_384/sources/r1/cms/prod/7846/1743095317846-h',link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040197/in/tam/v1/avc/1743102352279/hls/plain/media-1/index.m3u8'},
    { name: 'Match 6 : KKR vs RR',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/716/1743009340716-h',  link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040194/in/tam/v1/avc/1743016259425/hls/plain/media-1/index.m3u8'},
    { name: 'Match 5 : GT vs PBKS', image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/381/1742924060381-i', link:'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040191/in/tam/v1/avc/1742934609862/hls/plain/media-1/index.m3u8' },
    { name: 'Match 4 : DC vs LSG', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3625/1742838153625-i', link: 'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040188/in/tam/v2/avc/1742850489669/hls/plain/media-3/index.m3u8' },
    { name: 'Match 3 : CSK vs MI', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3245/1742751693245-i', link: 'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040185/in/tam/v1/avc/1742768833721/hls/plain/media-1/index.m3u8' },
    { name: 'Match 2 : SRH vs RR', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3479/1742736403479-i', link: 'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040182/in/tam/v1/avc/1742755874503/hls/plain/media-4/index.m3u8' },
    { name: 'Match 1 : RCB vs KKR', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_384/sources/r1/cms/prod/5846/1742663885846-h', link: 'https://hssportsprepack.akamaized.net/videos/cricket/1271337416/1540040179/in/tam/v1/avc/1742672487043/hls/plain/media-4/index.m3u8' }
],},
{name:'Replay',image:'https://cdni.autocarindia.com/Utils/ImageResizerV2.ashx?n=https://cdni.autocarindia.com/Stuff/Uploads/ArticleImages/638781481562352107_ipl_logo.jpg&w=883&h=662&q=70&c=1',shows:[
   {name:'Match 14 : RCB vs GT',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/2414/1743613572414-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040681/1540040682/214150b345344c118e2c831ef8e3c8f9/index_7.m3u8'},
  {name:'Match 13 : LSG vs PBKS',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/1330/1743526351330-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040678/1540040679/9ed2f90c694344e49e81e333c613e7fb/index_7.m3u8'},
  {name:'Match 12 : MI vs KKR',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/432/1743439350432-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040675/1540040676/70d0a2e701cf4605b059642799e6e072/index_7.m3u8'},
    {name:'Match 11 : RR vs CSK',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/6843/1743357696843-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040672/1540040673/b98ca2fcab3c4df2a14d2e91437ee0af/index_7.m3u8'},
    {name:'Match 10 : DC vs SRH',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/2107/1743340192107-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040669/1540040670/a55cf0ff2ba04b70baa0adebaccf446f/index_6.m3u8'},
  {name:'Match 9 : GT vs MI',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3853/1743270263853-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040666/1540040667/29c11bd13058495f927d83314cef098e/index_7.m3u8'},
  { name: 'Match 8 : CSK vs RCB',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/3783/1743182423783-i',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040663/1540040664/a01eb667f928406a99ca632f834a0d8f/index_7.m3u8'},
  { name: 'Match 7 : SRH vs LSG',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_384/sources/r1/cms/prod/4734/1743104034734-h',link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040660/1540040661/7a4397dda19b4b67be289ee24c7eb47b/index_4.m3u8'},
  { name: 'Match 6 : KKR vs RR',image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_384/sources/r1/cms/prod/4584/1743018824584-h',  link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040657/1540040658/cdd8f70ac2bd41898752d25cbd29b18b/index_7.m3u8'},
  { name: 'Match 5 : GT vs PBKS', image:'https://img10.hotstar.com/image/upload/f_auto,q_90,w_2048/sources/r1/cms/prod/381/1742924060381-i', link:'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040654/1540040655/b54786d4eff540bfbf068c505aa32382/index_7.m3u8' },
  { name: 'Match 4 : DC vs LSG', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3625/1742838153625-i', link: 'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040651/1540040652/e23e6c4c095e4a008e8010175e3e4370/index_7.m3u8' },
  { name: 'Match 3 : CSK vs MI', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3245/1742751693245-i', link: 'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040648/1540040649/04fee095f29a4f1dbf6fba0c99512b20/index_6.m3u8' },
  { name: 'Match 2 : SRH vs RR', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_1920/sources/r1/cms/prod/3479/1742736403479-i', link: 'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040645/1540040646/cd5ac2769738427791d45ed1f73de6ae/index_7.m3u8' },
  { name: 'Match 1 : RCB vs KKR', image: 'https://img10.hotstar.com/image/upload/f_auto,q_90,w_384/sources/r1/cms/prod/1850/1742700911850-h', link: 'https://vod01.akt.hotstar-cdn.net/videos/cricket/inallow-ipl-2025-1540040616/1540040617/15f996b76db64f2086da35ab3e7eca64/index_7.m3u8' }
],},
];


const Movies = () => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  
  useEffect(() => {
    if (currentVideo) {
      window.open(currentVideo, "_blank");
      setCurrentVideo(null);
    }
  }, [currentVideo]);

  const handleAlbumClick = (album) => {
    setSelectedAlbum(album);
    setCurrentVideo(null);
    setSearchQuery("");
  };

  const handleBackClick = () => {
    setSelectedAlbum(null);
    setCurrentVideo(null);
    setSearchQuery("");
  };

  const handleVideoClick = (video) => {
    setCurrentVideo(video.link);
  };

  const filteredVideos = selectedAlbum?.shows.filter((video) =>
    video.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="content">
        {selectedAlbum ? (
          <div className="album-details">
            <button onClick={handleBackClick} className="back-button">
              Back to Matches
            </button>
            <h2 className="hi">{selectedAlbum.name}</h2>
            <input
              type="text"
              placeholder="Search Matches..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-bar"
            />
            <div className="play">
              {filteredVideos.length > 0 ? (
                filteredVideos.map((video, index) => (
                  <div
                    key={index}
                    className="stream"
                    onClick={() => handleVideoClick(video)}
                  >
                    <img src={video.image} alt={video.name} />
                    <p>{video.name}</p>
                  </div>
                ))
              ) : (
                <p>No videos found</p>
              )}
            </div>
          </div>
        ) : (
          <div id="channel-player" className="play">
            {Ipl.map((album, index) => (
              <div
                key={index}
                className="stream"
                onClick={() => handleAlbumClick(album)}
              >
                <img src={album.image} alt={album.name} />
                <p>{album.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Movies;
